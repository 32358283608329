import React from 'react'
import Wrapper from '../../layouts/Wrapper'
import HeaderThree from '../../layouts/headers/HeaderThree'
import ProductDetailsArea from './ProductDetailsArea'
import ScrollToTop from '../common/scroll-to-top'

export default function ProductDetails() {
  return (
    <Wrapper>
      <ScrollToTop />
      <HeaderThree />
      <ProductDetailsArea />
    </Wrapper>
  )
}