import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import product_data, { ProductDataType } from '../../data/product_data';
import ReactGA from 'react-ga4';

const categories = ["All", ...Array.from(new Set(product_data.map((item) => item.category)))];

interface OptionType {
  id: number;
  title: string;
  type: string;
  image: string;
  price: number;
}

export default function ProductHome() {
  const [activeCategory, setActiveCategory] = useState("All");
  const [items, setItems] = useState(product_data);
  const [selectedProduct, setSelectedProduct] = useState<OptionType | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  // Filter unique products based on title and type "Pouch"
  const uniqueProducts = Array.from(
    new Map(
      product_data
        .filter(item => !item.type || item.type?.includes("Pouch")) // Include items where type is null/undefined or includes "Pouch"
        .map(item => [item.title, item]) // Use title as a unique key
    ).values()
  );

  const filterItems = (cateItem: string) => {
    setActiveCategory(cateItem);
    if (cateItem === "All") {
      const filteredProducts = Array.from(
        new Map(
          product_data
            .filter(item => item.type?.includes("Pouch")) // Only render "Pouch" versions
            .map(item => [item.title, item]) // Use title as a unique key
        ).values()
      );
      setItems(filteredProducts);
    } else {
      const findItems = product_data.filter((findItem) => findItem.category === cateItem);
      setItems(findItems);
    }
  };


  const handleButtonClick = (product: ProductDataType, actionType: 'order' | 'trial') => {
    const directNavigateProducts = ['Desi Bilona Ghee Cow', 'Desi Bilona Ghee Buffalo', 'Paneer'];
    if (directNavigateProducts.includes(product.title)) {
      navigate(`/product-details/${product.id}`); // Directly navigate to the product details page
      return;
    }

    if (actionType === 'trial') {
      // Find the 500ml variant of the product
      const selectedProduct = product_data.find(p => p.title === product.title && p.size.includes('500 ml'));
      if (selectedProduct) {
        // Pass query params for tab selection and size
        navigate(`/product-details/${selectedProduct.id}?tab=trial&size=500ml`);
        return;
      }
    }

    const option: OptionType = {
      id: product.id,
      title: product.title,
      type: product.type || 'default-type', // Provide a default value here
      image: product.image,
      price: product.price
    };
    setSelectedProduct(option);
    setShowPopup(true);
    ReactGA.event({
      category: 'Button',
      action: `Home Order Now ${product.id}`,
      label: 'Order Now'
    });
  };

  const handleOptionSelect = (option: OptionType) => {
    setShowPopup(false);
    setSelectedProduct(null);
    navigate(`/product-details/${option.id}`);
  };

  return (
    <div className='product-home'>
      <section className='service-feature-section white-bg space-top'>
        <div className="container">
          {/* Title Section */}
          <div className="row g-4 justify-content-between align-items-end mb-60">
            <div className="col-xxl-5 col-xl-7">
              <div className="section-title">
                <h5 className="p1-clr wow fadeInLeft" data-wow-delay="0.4s">
                  Our Products
                </h5>
                <h2 className="wow fadeInDown" data-wow-delay=".3s">
                  Nourishing the world from farm to table
                </h2>
              </div>
            </div>
            {/* <div className="col-xxl-5 col-xl-5">
              <div className="mixtup-filtering d-flex justify-content-end">
                <div className="filter-btns">
                  {categories.map((cate, i) => (
                    <React.Fragment key={i}>
                      <button
                        onClick={() => filterItems(cate)}
                        className={`filter-btn ${cate === activeCategory ? "active-filter" : ""}`}
                      >
                        {cate}
                      </button>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div> */}
          </div>

          {/* Product Cards */}
          <div className="product-grid">
            {uniqueProducts.map((item, i) => (
              <div key={i} className="product-card">
                <img src={item.image} onClick={() => handleButtonClick(item, 'order')} alt={item.title} className="product-image" />
                <div className="product-content">
                  <h3 className="product-title">{item.title}</h3>
                  <p className="product-description">{item.size}
                     {/* <span className='product-type'> {item.type} </span> */}
                     </p>
                  <div className="product-price-wrapper">
                    <span className="product-mrp">Rs {item.mrp}</span>
                    <span className="product-price">Rs {item.price}</span>
                    <span className="tax-text">* inclusive of all taxes</span>
                  </div>
                  <div className='product-cta'>
                    <button
                      onClick={() => handleButtonClick(item, 'order')}
                      className="order-now-btn"
                    >
                      Add to cart
                    </button>

                    <button
                      onClick={() => handleButtonClick(item, 'trial')}
                      className="subscribe-btn"
                    >
                      Start Trial
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {showPopup && selectedProduct && (
            <div className="select-product-popup-overlay">
              <div className="popup-container">
                <h3>Select an option for {selectedProduct.title}</h3>
                <div className="option-list">
                  {product_data
                    .filter(product => product.title === selectedProduct.title)
                    .map((option, index) => (
                      <div key={index} className="option-item">
                        <input
                          type="radio"
                          id={`option-${option.id}`}
                          name="product-option"
                          onChange={() => handleOptionSelect(option as OptionType)}
                        />
                        <label htmlFor={`option-${option.id}`} className="option-label">
                          <img src={option.image} alt={option.title} className="option-image" />
                          <span className="option-title">{option.size} {option.type}</span>
                          <span className="option-price">Rs {option.price}</span>
                        </label>
                      </div>
                    ))
                  }
                </div>
                <button className="close-popup-btn" onClick={() => setShowPopup(false)}>Close</button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}