import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCow, faGlassWhiskey, faCheckCircle, faBoxOpen, faTruck } from '@fortawesome/free-solid-svg-icons';

const MilkingProcess: React.FC = () => {
  const steps = [
    {
      id: 1,
      icon: faCow,
      title: 'Farm Fresh Herd',
      description: 'Our cows and buffaloes are kept in a clean, natural environment and fed a healthy, nutrient-rich diet to ensure quality milk production.'
    },
    {
      id: 2,
      icon: faGlassWhiskey,
      title: 'Milking',
      description: 'Fresh milk is hygienically extracted using modern equipment, ensuring minimal human contact for maximum purity.'
    },
    {
      id: 3,
      icon: faCheckCircle,
      title: 'Quality Check',
      description: 'The milk undergoes multiple quality checks for freshness, purity, and nutritional content before it’s processed further.'
    },
    {
      id: 4,
      icon: faBoxOpen,
      title: 'Packaging',
      description: 'The milk is packed in eco-friendly, food-grade bottles and pouches to maintain freshness and hygiene during delivery.'
    },
    {
      id: 5,
      icon: faTruck,
      title: 'Fresh Delivery',
      description: 'We ensure fast and efficient delivery to your doorstep so you can enjoy farm-fresh milk daily.'
    },
  ];

  return (
    <section className="milking-process-section">
      <div className="container">
        <h2 className="section-title">From Farm Fresh to Your Table</h2>
        <p className="section-description">
          Discover how our fresh milk makes its journey from the farm to your table, following a hygienic, sustainable, and transparent process.
        </p>
        
        <div className="process-steps">
          {steps.map((step) => (
            <div key={step.id} className="process-step">
              <div className="icon-container">
                <FontAwesomeIcon icon={step.icon} className="process-icon" />
              </div>
              <h3 className="step-title">{step.title}</h3>
              <p className="step-description">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MilkingProcess;