
import React from 'react';
import HomeHeader from './HomeHeader';
import HomeBanner from './HomeBanner';
import ProductHome from './ProductHome';
import AboutHome from './AboutHome';
import Testimonial from './Testimonial';
import Wrapper from '../../layouts/Wrapper';
import MilkingProcess from './MilkingProcess';

export default function Home() {
  return (
    <Wrapper>
      <HomeHeader />
      <HomeBanner />
      <AboutHome />
      <MilkingProcess />
      <ProductHome />
      <Testimonial />
    </Wrapper>
  )
}
