import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import './modal.scss';

const FloatingButton: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    area: '',
    subarea: '',
    interestedProduct: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const areaOptions = ['Area 1', 'Area 2', 'Area 3', 'Area 4'];
  const subareaOptions = ['Subarea 1', 'Subarea 2', 'Subarea 3', 'Subarea 4'];
  const productOptions = ['Product A', 'Product B', 'Product C', 'Product D'];

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setIsModalOpen(false);
  };

  // Open modal
  const handleOpenModal = () => setIsModalOpen(true);

  // Close modal
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {/* Floating Button */}
      <button
        className="floating-button"
        onClick={handleOpenModal}
      >
        <FontAwesomeIcon icon={faFileAlt} size="sm" />
      </button>

      {/* Modal */}
      <Modal
        show={isModalOpen}
        onHide={handleCloseModal}
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-with-bg">
          <Modal.Title>
            Get a Sample
            <p className="modal-subheading">Add details & we will call back to schedule a delivery</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Name Field */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label className="form-label">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
                className="form-input"
              />
            </Form.Group>

            {/* Mobile Field */}
            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Label className="form-label">Mobile</Form.Label>
              <Form.Control
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                placeholder="Enter your mobile number"
                className="form-input"
              />
            </Form.Group>

            {/* Area Dropdown */}
            <Form.Group className="mb-3" controlId="formArea">
              <Form.Label className="form-label">Area</Form.Label>
              <Form.Select
                name="area"
                value={formData.area}
                onChange={handleInputChange}
                className="form-input"
              >
                <option value="">Select Area</option>
                {areaOptions.map((area, index) => (
                  <option key={index} value={area}>
                    {area}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Subarea Dropdown */}
            <Form.Group className="mb-3" controlId="formSubarea">
              <Form.Label className="form-label">Subarea</Form.Label>
              <Form.Select
                name="subarea"
                value={formData.subarea}
                onChange={handleInputChange}
                className="form-input"
              >
                <option value="">Select Subarea</option>
                {subareaOptions.map((subarea, index) => (
                  <option key={index} value={subarea}>
                    {subarea}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Interested Product Dropdown */}
            <Form.Group className="mb-3" controlId="formInterestedProduct">
              <Form.Label className="form-label">Interested Product</Form.Label>
              <Form.Select
                name="interestedProduct"
                value={formData.interestedProduct}
                onChange={handleInputChange}
                className="form-input"
              >
                <option value="">Select Product</option>
                {productOptions.map((product, index) => (
                  <option key={index} value={product}>
                    {product}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Submit Button */}
            <Button
              variant="primary"
              type="submit"
              className="form-submit-btn"
              style={{ border: 'none' }}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FloatingButton;